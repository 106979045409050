<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'users-index' }" />

    <template v-if="user">
      <v-sheet elevation="3">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>
          <v-tab>Подписка</v-tab>
          <v-tab>Сброс пароля</v-tab>
          <v-tab>Push</v-tab>
          <v-tab>Логи</v-tab>

          <v-tab-item>
            <user-summary
              :user="user"
              :updating="updating"
              :deleting="deleting"
              @update="updateUser"
              @delete="deleteUser"
              @change-field="changeField"
              @confirm-email="confirmEmail"
            />
          </v-tab-item>

          <v-tab-item>
            <user-subscription :user="user" :fetch-user="fetchUser" />
          </v-tab-item>

          <v-tab-item>
            <user-change-password :updating="updating" @update="updateUser" />
          </v-tab-item>

          <v-tab-item>
            <user-push-notifications :user="user" />
          </v-tab-item>

          <v-tab-item>
            <user-logs :user-id="user.id" />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Пользователь с id "{{ id }}" не найден</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import UserSummary from "~/components/users/id/summary";
import UserSubscription from "~/components/users/id/subscription";
import UserChangePassword from "~/components/users/id/change-password";
import UserPushNotifications from "~/components/users/id/push-notifications";
import UserLogs from "~/components/users/id/logs";

export default {
  mixins: [editorForm],
  data() {
    return {
      user: null,

      loading: true,
      updating: false,
      deleting: false
    };
  },
  created() {
    this.fetchUser();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchUser() {
      this.loading = true;

      try {
        this.user = await this.$axios.$get(`users/${this.id}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Пользователь с id: "${this.id}" не найден ${html}`);
      } finally {
        this.loading = false;
      }
    },
    async updateUser(payload = {}) {
      if (this.updating || this.deleting) return;

      this.updating = true;

      const user = { ...this.user, ...payload };

      try {
        await this.$axios.$patch(`users/${this.id}`, user);

        await this.fetchUser();

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(user)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    async deleteUser() {
      if (this.updating || this.deleting) return;

      if (!confirm("Внимание! Удаление пользователя необратимо, продолжить?")) return;

      this.deleting = true;

      try {
        await this.$axios.$delete(`users/${this.user.id}`);
        await this.fetchUser();

        this.$snackbar.success("Пользователь удален");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить/получить пользователя:" + html);
      } finally {
        this.deleting = false;
      }
    },
    async confirmEmail() {
      try {
        this.user = await this.$axios.$post(`users/${this.id}/confirm-email`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не удалось подтвердить почту: ${html}`);
      }
    },
    changeField(payload) {
      this.commitChange(this.user, payload);
    }
  },
  components: {
    UserSummary,
    UserSubscription,
    UserChangePassword,
    UserPushNotifications,
    UserLogs
  }
};
</script>
