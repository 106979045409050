<template>
  <v-row class="props-list">
    <template v-for="(row, ind) in rows">
      <v-col :key="ind + '-title'" md="2">{{ row.title }}</v-col>
      <v-col :key="ind + '-value'" md="10">{{ row.value }}</v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  props: {
    analytics: {
      type: Object,
      required: true
    }
  },
  computed: {
    rows() {
      let rows = [];

      for (const service in this.analytics) {
        if (!this.analytics[service]) {
          rows.push({ title: service, value: "-" });
          continue;
        }

        for (const key in this.analytics[service]) {
          rows.push({ title: `${service}, ${key}`, value: this.analytics[service][key] });
        }
      }

      return rows;
    }
  }
};
</script>
