<template>
  <div>
    <span v-if="discount">
      {{ discount.amount | number }} ₽

      <span v-if="discount.type === 'promo-code'">
        по промокоду
        <router-link :to="{ name: 'promocodes-id', params: { id: discount.id } }">{{ discount.id }}</router-link>
      </span>
    </span>

    <span v-else>—</span>
  </div>
</template>

<script>
export default {
  props: {
    discount: {
      default: null,
      validator(value) {
        return value === null || typeof value?.id === "string";
      }
    }
  }
};
</script>
