<template>
  <v-row class="props-list">
    <v-col md="2">id</v-col>
    <v-col md="10">{{ user.id }}</v-col>

    <v-col md="2">Email</v-col>
    <v-col md="10">{{ user.email }}</v-col>

    <v-col md="2">Почта подтверждена</v-col>
    <v-col md="10">
      {{ user.emailConfirmed | yesNo }}
      <v-btn v-if="!user.emailConfirmed" class="ml-2" color="primary" small @click="$emit('confirm-email')">
        Подтвердить
      </v-btn>
    </v-col>

    <v-col md="2">Дата регистрации</v-col>
    <v-col md="10">{{ user.createdAt | dateTime }}</v-col>

    <v-col md="2">LTV</v-col>
    <v-col class="nobr" md="10">{{ user.totalPayments | number }} ₽</v-col>

    <v-col md="2">Статус</v-col>
    <v-col md="10">
      <user-status :deleted="user.isDeleted" />
    </v-col>

    <v-col md="2">Роль</v-col>
    <v-col md="10">
      <v-select v-model="roleProxy" :items="userRoles" label="Роль" item-value="uid" item-text="text" dense solo />
    </v-col>

    <v-col md="2">Имя</v-col>
    <v-col md="10">
      <v-text-field label="Имя" :value="user.name" @input="setField('name', $event)" />
    </v-col>

    <v-col md="2">Фамилия</v-col>
    <v-col md="10">
      <v-text-field label="Фамилия" :value="user.lastName" @input="setField('lastName', $event)" />
    </v-col>

    <v-col md="2">Никнейм</v-col>
    <v-col md="10">
      <v-text-field label="Никнейм" :value="user.nickname" @input="setField('nickname', $event)" />
    </v-col>

    <v-col md="2">IP-адреса</v-col>
    <v-col md="10">
      <v-text-field v-model="boundIPs" label="IP-адреса" />
    </v-col>

    <v-col md="2">Источник</v-col>
    <v-col md="10">{{ user.site }}</v-col>

    <v-col md="2">Комментарий</v-col>
    <v-col md="10">
      <v-textarea label="Комментарий" :value="user.comment" auto-grow @input="setField('comment', $event)" />
    </v-col>
  </v-row>
</template>

<script>
import { stringToMultipleFilter } from "~/core/helpers";

import { userRoleEnum, getUserRoleMap } from "~/libs/user/user-role";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

import UserStatus from "~/components/users/user-status";

export default {
  mixins: [editorFields],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userRoles: getUserRoleMap()
    };
  },
  computed: {
    roleProxy: {
      get() {
        return userRoleEnum[this.user.role];
      },
      set(value) {
        this.setField("role", value);
      }
    },
    boundIPs: {
      get() {
        return this.user.boundIPs.join(", ");
      },
      set(value) {
        this.setField("boundIPs", stringToMultipleFilter(value));
      }
    }
  },
  components: {
    UserStatus
  }
};
</script>
