<template>
  <v-form class="pa-3" autocomplete="off" @submit.prevent="changePassword">
    <v-text-field v-model="newPassword.password" label="Новый пароль" required />
    <v-text-field v-model="newPassword.passwordRepeat" label="Новый пароль еще раз" required />

    <div class="text-right">
      <v-btn type="submit" color="accent" :loading="updating">Сбросить</v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    updating: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newPassword: {
        password: "",
        passwordRepeat: ""
      }
    };
  },
  methods: {
    changePassword() {
      if (this.newPassword.password && this.newPassword.passwordRepeat) {
        this.$emit("update", { ...this.newPassword });
      } else {
        this.$snackbar.message("Пароль не обновлен т.к не указано новое значение");
      }
    }
  }
};
</script>
