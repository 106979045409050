<template>
  <div class="pa-3">
    <user-overview :user="user" @change-field="$emit('change-field', $event)" @confirm-email="$emit('confirm-email')" />

    <v-divider class="my-3" />

    <div class="text-h6 mb-3">Мета</div>

    <user-meta :analytics="user.analytics" />

    <v-divider class="my-3" />

    <div class="text-h6 mb-3">Параметры</div>

    <v-row class="props-list">
      <v-col md="2">Публичный портфель</v-col>
      <v-col md="10">{{ user.settings.isFavoritesPublic | yesNo }}</v-col>
    </v-row>

    <v-divider class="my-3" />

    <div class="text-h6 mb-3">Приглашение</div>

    <v-row v-if="user.invite" class="props-list">
      <v-col md="2">id</v-col>
      <v-col md="10">{{ user.invite.id }}</v-col>

      <v-col md="2">Активировано</v-col>
      <v-col md="10">{{ user.invite.activatedAt | dateTime }}</v-col>
    </v-row>

    <div v-else>Пользователь зарегистрировался без приглашения</div>

    <v-divider class="my-3" />

    <div class="d-flex">
      <v-btn class="mr-auto" color="error" :loading="deleting" @click="$emit('delete')">Удалить</v-btn>
      <v-btn color="accent" :loading="updating" @click="$emit('update')">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import UserOverview from "./overview";
import UserMeta from "../meta";

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    },
    deleting: {
      type: Boolean,
      required: true
    }
  },
  components: {
    UserOverview,
    UserMeta
  }
};
</script>
