<template>
  <div class="pa-3">
    <v-timeline v-if="logs && logs.length" dense>
      <v-timeline-item v-for="log of logs" :key="log.id" :color="pickHttpStatusColor(log)" right>
        <div>
          <router-link :to="{ name: 'logs-id', params: { id: log.id } }">
            {{ log.action }}
          </router-link>
          <div>
            {{ log.createdAt | dateTime }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <div v-else-if="!loading" class="text-h6">Не удалось получить логи для пользователя с id: "{{ userId }}"</div>
    <div v-else-if="logs" class="text-h6">Логов не найдено</div>
  </div>
</template>

<script>
import { HttpCode } from "~/libs/http-code";
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,

      logs: null
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      try {
        const { data: logs } = await this.$axios.$get("logs", { params: { limit: 30, userId: this.userId } });

        this.logs = logs;
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не удалось получить логи для пользователя с id: "${this.id}" ${html}`);
      }
    },

    pickHttpStatusColor({ statusCode }) {
      return new HttpCode(statusCode).color;
    }
  }
};
</script>
