<template>
  <div class="pa-3">
    <v-row class="props-list">
      <v-col md="2">Активна</v-col>
      <v-col md="10">{{ user.subscription.isActive | yesNo }}</v-col>

      <v-col md="2">Продукт</v-col>
      <v-col md="10">{{ formatTariff(user.subscription.product) }}</v-col>

      <v-col md="2">Дата создания</v-col>
      <v-col md="10">{{ user.subscription.createdAt | dateTime }}</v-col>

      <v-col md="2">Дата окончания</v-col>
      <v-col md="10">{{ user.subscription.expiredAt | dateTime({ showTime: false }) }}</v-col>

      <v-col md="2">Скидка</v-col>
      <v-col md="10">
        <subscription-discount :discount="user.subscription.discount" />
      </v-col>
    </v-row>

    <v-divider class="my-3" />

    <div class="text-h6">Установка даты окончания подписки</div>

    <v-date-picker v-model="expiredAt" class="my-3" elevation="3" />

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="loading" @click="update">Обновить</v-btn>
    </div>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { DateHelper } from "~/core/date";

import SubscriptionDiscount from "./discount";

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    fetchUser: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      expiredAt: DateHelper.formatDate(DateHelper.appendDay(Date.now(), 1))
    };
  },
  methods: {
    async update() {
      if (!confirm("Внимание! Вы меняете статус подписки. Продолжить?")) return;

      this.loading = true;

      try {
        await this.$axios.$put(`subscription/${this.user.id}`, { expiredAt: this.expiredAt });
        await this.fetchUser();

        this.$snackbar.success("Подписка обновлена");
      } catch (e) {
        const html = new ApiValidationError(e, ["user", "expiredAt"]).toHtml();

        this.$snackbar.error("Не удалось обновить подписку: " + html);
      } finally {
        this.loading = false;
      }
    },

    formatTariff(productId) {
      return this.$store.getters["dictionary/productById"](productId).title;
    }
  },
  components: {
    SubscriptionDiscount
  }
};
</script>
