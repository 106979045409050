<template>
  <div class="pa-3">
    <v-simple-table v-if="tokens.length > 0">
      <thead>
        <tr>
          <th>Токен</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="token of tokens" :key="token">
          <td>{{ token }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <div v-else>Список токенов пуст</div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    tokens() {
      // TODO: Удалить optional chain после релиза бэка
      return this.user.pushNotifications?.tokens || [];
    }
  }
};
</script>
